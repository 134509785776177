import React from "react"
import { Link } from "gatsby"

import Logo from "../images/logo.png"

//import appStoreIcon from "../images/app-store-icon.png"
//import googlePlayIcon from "../images/google-pay-icon.png"
import fbIcon from "../images/fb-icon.svg"
import twitterIcon from "../images/twitter.svg"
import instacon from "../images/insta-icon.svg"


import video from "../images/video-icon.svg"
import lineImage from "../images/line2.png"

import mailIcon from "../images/mail-icon.svg"
import callIcon from "../images/call-icon.svg"
import livechat from "../images/live-chat-icon.svg"

import Layout from "../components/layout"


import image1 from "../images/core.png"
import image2 from "../images/comfort.png"
import image3 from "../images/convenience.png"
import handIcon from "../images/hand.svg"
import chatbottom from "../images/chat-bottom-icon.svg"
import chatProfile from "../images/chat-profile.png"
import closeChat from "../images/close-icon.svg"
import SEO from "../components/seo"

const IndexPage = (props) => (
  <Layout rest={props} layoutType="main">
    <SEO title="Servicecontract.com - What's Covered" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
    <div className="chat-content">
      <div className="chat-content-circle">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
          <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
            <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
              <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
              <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
              <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
              <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
            </g>
          </g>
        </svg>
      </div>
      <div className="chat-box">

        <div className="chatbox-inner">
          <div className="chat-profile">
            <img className="" src={chatProfile} alt="" />
          </div>
          <span className="close-chat"><img className="" src={closeChat} alt="" /></span>
          <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
        </div>
        <img className="chat-bottom" src={chatbottom} alt="" />
      </div>

    </div>

   

    <section className="covered-container">
      <div className="container">
        <h3 className="title">What's Covered?</h3>
        <p className="subtitle">We think it should be simple: Core, Comfort, Convenience.*</p>
        <div className="covered-card">
          <div className="card">
            <div className="inner-card">
              <div className="header">
                <img src={image1} alt="image1" />
              </div>
              <div className="card-body">
                <h4>Core</h4>
                <p>Core components are the things that make your car go and are typically the costliest to repair. Examples include your engine and transmission.</p>
              </div>

            </div>
          </div>
          <div className="card">
            <div className="inner-card">
              <div className="header">
                <img src={image2} alt="image1" />
              </div>
              <div className="card-body">
                <h4>Comfort</h4>
                <p>Comfort components allow you to drive comfortably and include things like your air conditioning and suspension.</p>
              </div>

            </div>
          </div>
          <div className="card">
            <div className="inner-card">
              <div className="header">
                <img src={image3} alt="image1" />
              </div>
              <div className="card-body">
                <h4>Convenience</h4>
                <p>Convenience services ensure your life isn't disrupted while your car is in the shop.</p>
              </div>

            </div>
          </div>
        </div>
        {/* <button className="btn get-price-btn">Get your Price</button> */}
        <Link className="btn get-price-btn" to="/vehicleInfo">Get Your Price</Link>
      </div>
    </section>

    {/* -------- */}
   

    <div className="break-section">

      <img src={lineImage} alt="line" />
    </div>

    <section className="contact-container">
      <div className="container">
        <div className="logo-container">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="contact-inner">
          <div className="first-col">
            <ul>
              <li> <img src={mailIcon} alt="mail" /> <p>help@servicecontract.com</p>
                <span>24/7 -  Available when you need us</span>
              </li>
              <li> <img src={callIcon} alt="call" /><p>1-800-800-3629</p></li>
              <li> <img src={livechat} alt="liev chat" /><p>Live Chat</p></li>
            </ul>
          </div>
          <div className="second-col">
            <ul>
              <li><Link to="/vehicleInfo">Get Your Price</Link></li>
              <li><Link to="">What's Covered</Link></li>
              <li><Link to="">Extra Benefits</Link></li>
              <li><Link to="">Claim</Link></li>
              {/* <li><Link to="">Our Partners</Link></li> */}
              <li><Link to="">Who We Are</Link></li>
              <li><Link to="">FAQ</Link></li>
              {/* <li><Link to="">API</Link></li> */}
            </ul>
          </div>
          <div className="third-col">
            <ul>
              <li><Link to="">Who We Are</Link></li>
              {/* <li><Link to="">Blog</Link></li> */}
              <li><Link to="">Extra Benefits</Link></li>
              <li><Link to="">Contact</Link></li>
              {/* <li><Link to="">Join the Team</Link></li> */}
              <li><Link to="/legalstuff">Legal Stuff</Link></li>
              <li><Link to="/privacy">Privacy Policy</Link></li>
              <li><Link to="/terms">Terms of Service</Link></li>
            </ul>
          </div>
          <div className="fourth-col">
            {/* <div className="store-icon">
              <h6>Get Our App</h6>
              <span><img src={appStoreIcon} alt="Apple store" /></span>
              <span><img src={googlePlayIcon} alt="Googole play store" /></span>
            </div> */}

            <div className="follow-icon">
              <h6>Follow Us</h6>
              <span><img src={fbIcon} alt="facebook" /></span>
              <span><img src={twitterIcon} alt="Twitter" /></span>
              <span><img src={instacon} alt="instagra," /></span>
              <span><img src={video} alt="youtube" /></span>
            </div>

          </div>
        </div>
        <div className="copyright">
          <small>Copyright servicecontract.com © {new Date().getFullYear()}</small>
        </div>
      </div>


    </section>

  </Layout>
)

export default IndexPage
